/* Set the HTML and body to take up the entire viewport */
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Set the root container to take up the entire viewport */
#root {
  height: 100%;
  width: 100%;
}


/* Hide Mobile Navbar in Desktop View */
@media (min-width: 769px) {
    .MobileNavbar {
        display: none;
    }
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
    /* Other styles for mobile view */
}


/* Mobile Navbar Background Color */
/* CustomNavbar.css */
.Header {
    background: #1B065E;
    color: #fff;
    padding: 20px 20px;
    position: relative; /* Ensure relative positioning for absolute positioning */
    display: block; /* Show the Header by default */
    transition: background-color 0.5s ease; /* Smooth transition for background color change */
}




.Navbar {
    background: #1B065E;
    color: #fff;
    padding: 25px 25px;
    position: relative; /* Position the Navbar relatively */
}

.container {
    
    align-items: center;
    justify-content: space-between;
    position: relative; /* Ensure relative positioning for absolute positioning */
}

.logo {
    width: 200px; /* Adjust width as needed */
    height: 90px; /* Adjust height as needed */
    background-image: url('./image/logo.png'); /* Path to your logo image */
    background-size: cover; /* Ensure the logo covers the entire space */
    background-repeat: no-repeat;
    background-position: center;
    position: absolute; /* Position the logo absolutely */
    top: -20px; /* Adjust the distance from the top as needed */
    left: 0px; /* Position the logo on the left side */
    z-index: 1; /* Ensure the logo stays above other elements */

    
}



nav {
    position: absolute; /* Position the nav menu absolutely */
    top: 100%; /* Center the menu vertically */
    right: 10px; /* Position the menu on the right side */
    transform: translateY(-50%); /* Adjust for vertical centering */
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

nav ul li {
    display: inline;
    margin-right: 50px;
}

nav ul li a {
    color: #FFF;
    text-decoration: none;
}

nav ul li a:hover {
    text-decoration: none;
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
    .Header {
        display: none; /* Hide the Header on mobile view */
    }
    .Navbar {
        display: none; /* Hide the Navbar on mobile view */
    }
    .logo {
        width: 100px; /* Adjust logo width for mobile view */
        height: 100px; /* Adjust logo height for mobile view */
        top: -39px; /* Adjust logo top position for mobile view */
        left: 5px; /* Adjust logo left position for mobile view */
    }
    nav {
        right: 5px; /* Adjust menu right position for mobile view */
    }
}


/* Hide Mobile Navbar in Desktop View */
@media (min-width: 769px) {
    .MobileNavbar {
        display: none;
    }
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
    /* Other styles for mobile view */
}

@media (min-width: 769px) {
    .MobileNav {
        display: none; /* Hide MobileNav component in desktop view */
    }
}

/*Social-Icons*/

.social-icons {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.social-icons li {
    margin-right: 30px;
}

.social-icons li:last-child {
    margin-right: 25px;
}

.social-icons a {
    display: inline-flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
}

.social-icons svg {
    width: 15px;
    height: 15px;
    color: #fff;
}
/*Social-Icons*/


/*Menu-links*/

.Menu-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.Menu-links li {
    margin-right: 15px;
}

.Menu-links li:last-child {
    margin-right: 20px;
}

.Menu-links a {
    display: inline-flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 120px;
    height: 40px;
    border-radius: 0%;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 4px 4px rgba(255, 255, 0, 0.8);
    transition: border-radius 0.8s ease, box-shadow 0.8s ease; /* Add smooth transition for border-radius and box-shadow */
}

.Menu-links a:hover {
    border-radius: 0; /* Change border-radius on hover */
    box-shadow: 0 4px 4px rgba(255, 0, 0, 0.0); /* Change box-shadow on hover */
}

.Menu-links svg {
    width: 24px;
    height: 24px;
    color: #fff;
}
/*Menu-links*/



.slide {
  position: relative;
}

.slide-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
   /* Adjust background color and opacity as needed */
  padding: 10px;
}











